<template>

  <v-card
    max-width="200"
    my-10
    v-on="$listeners"
    elevation="2"
    @click="_goDetail"
  >
    <v-img
      class="white--text align-end"
      height="200px"
      v-if="$attrs.item.images"
                :src="$attrs.item.images[0].fileUrl"
                :aspect-ratio="1 / 1"

    >
      <v-card-title style="font-size: 12pt"
        >{{ $attrs.item.shortName }}
      </v-card-title>
    </v-img>
    <v-card-actions>
      <v-row justify="center">
        <v-btn
          class="ma-6 text-capitalize text-lowercase success white--text"
          outlined
          dark
          small
          style="color: black"
          @click.stop="_addToCollection"
        >
          Нэмэx+
        </v-btn>
      </v-row>
    </v-card-actions>

    <v-dialog v-model="dialog">
      <v-card>
        <v-toolbar>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Trip</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false"> Цуглуулагт нэмэx </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <h1>kdjfkjsdkfj</h1>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
const fb = require("@/firebaseConfig.js");
export default {
  data() {
    return {
      dialog: false,
    };
  },
  computed: {},
  methods: {
    _goDetail() {
      this.dialog = true;
    },
    _isInCollection() {
      console.log();
    },
    _addToCollection() {
      console.log(this.$attrs.item);
      var xxx = {
        locRef: this.$attrs.item.ref,
        gpsfield: this.$attrs.item.gpsfield,
        name: this.$attrs.item.name,
      };
      if (this.$attrs.item.images) {
        xxx.images = this.$attrs.item.images;
      }
      console.log(xxx)
      fb.db
        .collection(this.$attrs.tripdata.ref.path + "/saved")
        .doc(this.$attrs.item.id)
        .set(xxx, { merge: true });
    },
  },
};
</script>